//This file is used to build the telehealth and image secition of the client page

import { Box, Grid, Button } from "@mui/material";
import HeadshotAndDescription from "../providers/ClientsPhotos";

const teamMembers = [
  {
    imageSrc: "assets/images/northlandOfficePhotos/FinalHeadshots/Heather.png",
    name: "Dr. Heather Rose-Carlson",
    link: "https://doxy.me/drhrcarlson",
  },
  {
    imageSrc: "assets/images/northlandOfficePhotos/FinalHeadshots/Christina.png",
    name: "Christina Finlo",
    link: "https://doxy.me/cfinlo",
  },
  {
    imageSrc: "assets/images/northlandOfficePhotos/FinalHeadshots/Alex.png",
    name: "Alex Ross",
    link: "https://doxy.me/arossnps",
  },
  {
    imageSrc: "assets/images/northlandOfficePhotos/FinalHeadshots/Peggy.png",
    name: "Peggy Romero",
    link: "https://doxy.me/PeggyRomero",
  },
  {
    imageSrc: "assets/images/northlandOfficePhotos/FinalHeadshots/Rebecca.png",
    name: "Rebecca Oachs",
    link: "https://doxy.me/roachs",
  },
  {
    imageSrc: "assets/images/updatedpics/adamHeadshot.jpg",
    name: "Adam Huot",
    link: "https://doxy.me/ahuot",
  },
  {
    imageSrc: "assets/images/headshots/Claire.jpg",
    name: "Claire Dzierzak",
    link: "https://doxy.me/cdzierzak",
  },
  {
    imageSrc: "assets/images/updatedpics/lindziHeadshot.jpg",
    name: "Lindzi Campbell",
    link: "https://doxy.me/lindzi",
  },
  
];

const TeamMember = ({ imageSrc, name, link }) => (
  <Grid item xs={12} lg={3} md={6}>
    <HeadshotAndDescription
      imageSrc={imageSrc}
      name={name}
      description={
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: "black",
              color: "white",
              "&:hover": { backgroundColor: "black", color: "white" },
            }}
          >
            Telehealth
          </Button>
        </a>
      }
    />
  </Grid>
);

const TeamGrid = () => (
  <Box sx={{ my: 4 }}>
    <Grid container>
      <Grid container spacing={{ xs: 12, lg: 3, md: 6 }}>
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </Grid>
    </Grid>
  </Box>
);

export default TeamGrid;
