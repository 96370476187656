import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Layout.css";
import { useTheme } from "@mui/material/styles";

const Layout = () => {
  const theme = useTheme();
  return (
    <div className="layout" style={{ backgroundColor: theme.palette.secondary.main }}>
      <main style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: theme.palette.secondary.main }}>
        <Header />
        <div style={{ backgroundColor: theme.palette.secondary.main, paddingTop: "2%",paddingLeft:"5%",paddingRight:"5%" ,paddingBottom:"5%"  }}>
          <Outlet />
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
